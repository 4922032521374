export const setAppUrl = (baseUrl) => {
  const isLastCharsSlash = baseUrl[baseUrl.length - 1] === "/";
  if (isLastCharsSlash) {
    baseUrl = baseUrl.slice(0, -1);
  }
  window.__fo_app_url = baseUrl;
};

export const getUrlRelativeToApp = (relativeUrl) => {
  return window.__fo_app_url + relativeUrl;
};
