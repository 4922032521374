import axios from 'axios';
import { omit, pick } from 'lodash';

function printInvalidFieldsInConsole(data, allowedFields) {
    if (process.env.NODE_ENV !== 'production') {
        const invalidFields = omit(data, allowedFields);
        if (Object.keys(invalidFields).length > 0) {
            console.error("Invalid properties removed from patch request", invalidFields);
        }
    }
}

function convertObjectToPatchRequest(data, allowedFields) {
    const patchData = [];
    for (const [key, value] of Object.entries(pick(data, allowedFields))) {
        patchData.push({
            op: 'add',
            path: `/${key}`,
            value
        });
    }
    return patchData;
}

export async function httpPatch(url, data, allowedFields) {
    printInvalidFieldsInConsole(data, allowedFields);
    return axios.patch(url, convertObjectToPatchRequest(data, allowedFields));
}
