import { sv, enUS } from "date-fns/locale";
import { registerLocale } from "react-datepicker";
import intl from "react-intl-universal";

import moment from "moment";
import "moment/locale/sv";
import axios from "axios";

// common locale data for react-int-universal
require("intl/locale-data/jsonp/en.js");
require("intl/locale-data/jsonp/sv.js");

const dateFnsLocales = {
  "en-US": enUS,
  "sv-SE": sv,
};

const reactIntUniversalLocales = {
  "en-US": require("../locales/en-US.json"),
  "sv-SE": require("../locales/sv-SE.json"),
};

const registerDatePickerLocales = () => {
  for (let locale in dateFnsLocales) {
    const localeParts = locale.split("-");
    registerLocale(localeParts[0], dateFnsLocales[locale]);
  }
};

export const getLocale = () => window.__fo_locale;

export const getDateFnsLocale = () => dateFnsLocales[getLocale()];

export const initLocale = (currentLocale) => {
  const newLocale = currentLocale in reactIntUniversalLocales ? currentLocale : "sv-SE";
  if (newLocale === window.__fo_locale) {
    return Promise.resolve();
  }
  window.__fo_locale = newLocale;

  axios.defaults.headers["Accept-Language"] = newLocale;

  const [lang] = getLocale().split("-");
  moment.locale(lang);
  registerDatePickerLocales();
  return intl.init({
    currentLocale: getLocale(),
    locales: reactIntUniversalLocales,
  });
};
