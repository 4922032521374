import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import cookie from "react-cookies";
import history from "../history";
import qs from "query-string";

const COOKIE_JWT = "jwt_store";

const initialState = {
  venueId: null,
  billingProvider: null,
  hasAuth: false, // deprecated
  authInit: false,
  userId: null,
  userVenues: null,
};

const boAuthSlice = createSlice({
  name: "bo-auth",
  initialState: initialState,
  reducers: {
    setUserVenues(state, action) {
      const userVenues = action.payload;
      state.userVenues = userVenues;
    },
    setUserId(state, action) {
      const userId = action.payload;
      axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.load(COOKIE_JWT)}`;
      state.userId = userId;
    },
    setVenueId(state, action) {
      let venueId = action.payload;
      if (venueId !== null) {
        venueId = parseInt(venueId);
      }

      const doesVenueExist = state.userVenues?.find((uv) => uv.id === venueId);
      if (!doesVenueExist) {
        venueId = null;
      }

      axios.defaults.headers.common["X-VenueId"] = venueId;
      state.venueId = venueId;
      if (venueId > 0) {
        axios.put("/api/auth/current-venue", { venueId });
      }
    },
    reset(state) {
      axios.defaults.headers.common["Authorization"] = null;
      axios.defaults.headers.common["X-VenueId"] = null;
      return { ...initialState, authInit: state.authInit };
    },
    setInitSuccess(state) {
      state.authInit = true;
    },
  },
});

export const { setVenueId } = boAuthSlice.actions;

export default boAuthSlice.reducer;

const handleAuthAxiosSuccess = (dispatch, data, state) => {
  dispatch(boAuthSlice.actions.setUserVenues(data.userVenues));
  dispatch(boAuthSlice.actions.setUserId(data.userId));
  const isVenueSet = state.boAuth.venueId > 0;
  if (!isVenueSet && data.userVenues.length > 0) {
    dispatch(boAuthSlice.actions.setVenueId(
      data.currentVenueId === null ? data.userVenues[0].id : data.currentVenueId
    ));
  }
  dispatch(boAuthSlice.actions.setInitSuccess());
};

export const login = (email, password, onFail) => async (dispatch, getStore) => {
  try {
    const { data } = await axios.post("/api/2/auth/login", { email, password });
    handleAuthAxiosSuccess(dispatch, data, getStore());
    const venueId = getStore().boAuth.venueId;
    const params = qs.parse(window.location.search);

    let returnUrl = params.returnUrl || "/backoffice/";
    if (!(venueId > 0)) {
      returnUrl = "/backoffice/my-account";
    }
    console.info("returnUrl" + returnUrl)
    history.push(returnUrl);

  } catch (error) {
    onFail();
  }
};

// ------------------------------
// thunks

export const reset = () => async (dispatch) => {
  cookie.remove(COOKIE_JWT, { path: "/" });
  dispatch(boAuthSlice.actions.reset());

  // backwards compatible with old versions of http cookies
  await axios.get("/api/auth/logout");
};

export const logout = () => async (dispatch) => {
  dispatch(reset());
  history.push("/backoffice/login");
};

export const checkAuth = () => async (dispatch, getStore) => {
  try {
    const { data } = await axios.get("/api/auth/has-auth");
    handleAuthAxiosSuccess(dispatch, data, getStore());
  } catch (error) {
    dispatch(reset());
  }
  dispatch(boAuthSlice.actions.setInitSuccess());
};

// -----------------------------------
// selectors

export const currentVenueSelector = (state) => {
  let venue = state.boAuth.userVenues?.find((v) => state.boAuth.venueId === v.id);
  return venue;
};

export const hasAuthSelector = (state) => {
  return state.boAuth.userId > 0;
};
