import React from "react";
import ReactGA from "react-ga4";
import history from "../history";

function sendPageView(location) {
  const url = `${location.pathname}${location.search}`;
  ReactGA.send({ hitType: "pageview", page: url });
}

function GAListener({ children }) {
  React.useEffect(() => {
    sendPageView(history.location);
    history.listen(sendPageView);
  }, []);

  return children;
}

export default GAListener;
