import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  helpMenuItems: [], // dimensions: title, video
};

const boNavSlice = createSlice({
  name: "bo-nav",
  initialState: initialState,
  reducers: {
    setAppBarTitle(state, action) {
      const { title, helpMenuItems = [] } = action.payload;
      state.title = title;
      state.helpMenuItems = helpMenuItems;
    },
  },
});

export const { setAppBarTitle } = boNavSlice.actions;

export default boNavSlice.reducer;
