import actionTypes from "./actionTypes";
import {createReducer} from "@reduxjs/toolkit";
import {CurrentVenueModel} from "../../types/store/venue/CurrentVenueModel";
import {FixMeLater} from "../../fix-me-later";

export interface VenueState {
    current: CurrentVenueModel | null,
    fbPixel: string | null,
    allMenus: FixMeLater,
    mainMenus: FixMeLater,
    menuExtra: FixMeLater,
}

const initialState: VenueState = {
    current: null,
    fbPixel: null,
    allMenus: {},
    mainMenus: [],
    menuExtra: {},
};

const venueReducer = createReducer(initialState, {
    [actionTypes.LOAD_VENUE_SUCCESS]: (state, action) => {
        const venue = action.payload;
        state.current = venue;
        state.allMenus = venue.menuMetaData.allMenus;
        state.mainMenus = venue.menuMetaData.mainMenus;
        state.menuExtra = venue.menuMetaData.menuExtra;
    },
});

export default venueReducer;
