import actionTypes from "./action-types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  current: null,
  currentItems: [],
  status: null,
  loading: false,
};

export default createReducer(initialState, {
  [actionTypes.TRANSACTION_LOAD_VIA_SAGA]: (state) => {
    state.loading = true;
    state.status = null;
  },
  [actionTypes.LOAD_TRANSACTION]: (state) => {
    state.loading = true;
  },
  [actionTypes.RESET]: (state) => {
    return initialState;
  },
  [actionTypes.LOAD_TRANSACTION_DONE]: (state, action) => {
    const { transaction, items } = action.payload;
    state.current = transaction;
    state.currentItems = items;
    state.status = transaction.status;
    state.loading = false;
  },
});
