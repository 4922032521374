import cogoToast from "cogo-toast";


export async function showSaveToast(promise) {
    try {
        if (Array.isArray(promise)) {
            await Promise.all(promise);
        }
        else {
            await promise;
        }
        cogoToast.success("Sparat");
        return true;
    } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
            console.error("Problem when saving", err);
        }
        cogoToast.error("Ett fel uppstod");
        return false;
    }
}
