import {filter} from "lodash";
import {createSlice} from "@reduxjs/toolkit";
import {getCartCookieValue, persistCartToCookie, mergeEqualCartItems} from "./helpers";
import ReactGA from "react-ga4";
import * as cartItemActions from "../cart-item/reducer";
import {CartItemExtra} from "../cart-item/reducer";
import {CartItemModel} from "../../types/store/cart/CartItemModel";

const version = 1;

type CartState = {
    version: number,
    items: Array<CartItemModel>
}

function getInitialState(): CartState {
    let emptyState: CartState = {
        version: version,
        items: [],
    };
    
    try {
        let stateFromCookie = getCartCookieValue() as CartState;
        if( stateFromCookie === null ){
            return emptyState;
        }
        if( !("items" in stateFromCookie) ){
            return emptyState;
        }
        if( stateFromCookie.version !== version ){
            return emptyState;
        }
        return {
            version: version,
            items: stateFromCookie.items.map(item => {
                const {menuItemId, numItems, comment, extras} = item;
                return {
                    menuItemId,
                    numItems,
                    comment,
                    extras
                }
            })
        };
    } catch (error) {
    }
    return emptyState;
}


const cartReducer = createSlice({
    name: "cart",
    initialState: getInitialState(),
    reducers: {
        setAllItems(state, action) {
            state.items = action.payload;
            persistCartToCookie(state);
        },
        updateCart(state, action: {
            payload: { numItems: number, comment: string, cartItemIndex: number, extras: Array<CartItemExtra> },
            type: string
        }) {
            const {numItems, extras, comment, cartItemIndex} = action.payload;

            let itemToUpdate = state.items[cartItemIndex];
            itemToUpdate.numItems = numItems;
            itemToUpdate.comment = comment;
            itemToUpdate.extras = extras;
            state.items = mergeEqualCartItems(state.items);

            // remove all items that are 0
            state.items = filter(state.items, (item) => item.numItems > 0);
            persistCartToCookie(state);
        },
        pushToCart(state, action: {
            payload: { menuItemId: number, numItems: number, comment: string, extras: Array<CartItemExtra> },
            type: string
        }) {
            const {menuItemId, numItems, comment, extras} = action.payload;
            state.items.push({
                menuItemId,
                numItems,
                comment,
                extras
            });
            state.items = mergeEqualCartItems(state.items);
            persistCartToCookie(state);
        },
    },
});

export default cartReducer.reducer;

// ------------------------------
// thunks
// ------------------------------

export const addToCart = (menuItemId: number, numItems: number, comment: string, extras: Array<CartItemExtra> = []) => (dispatch, getState) => {
    const state = getState();
    ReactGA.event({
        category: "Cart",
        action: `${numItems > 0 ? "adding items" : "removing items"} - venue ${state.venue.current.id}`,
    });
    dispatch(cartReducer.actions.pushToCart({menuItemId, numItems, comment, extras}));
};

export const updateCartItem = (cartItemIndex: number, numItems: number, comment: string, extras: Array<CartItemExtra>) => (dispatch) => {
    if (numItems === 0) {
        dispatch(cartItemActions.closeModal());
    }
    dispatch(cartReducer.actions.updateCart({cartItemIndex, numItems, comment, extras}));
};

export const updateCartItemNumItems = (cartItemIndex, numItems) => (dispatch, getState) => {
    dispatch(cartReducer.actions.updateCart({cartItemIndex, ...getState().cart.items[cartItemIndex], numItems}));
};

export const setCartItems = (items) => (dispatch) => {
    dispatch(cartReducer.actions.setAllItems(items));
};

export const clearCartItems = () => (dispatch) => {
    dispatch(cartReducer.actions.setAllItems([]));
};
