import qs from 'query-string';
import cookie from 'react-cookies';

const formCookieName = 'cookie.form';

export const getInitialState = () => {
  const query = qs.parse(window.location.search);
  if (query.source) {
    // save cookie 24 hours as a traffic source. it can be overridden if somebody else
    // comes as a source.
    cookie.save(getFormValueFromCookie('source'), query.source, { path: '/', maxAge: 60 * 60 * 24 });
  }
  return {
    promoCode: null,
    promoCodeIsValid: null,
    creating: false,
    errors: [],
    dateSlots: [],
    timeSlots: [],
    deliveryMethods: {
      room: false,
      table: false,
      takeAway: false,
      carryOut: false,
    },
    paymentMethods: {
      swish: false,
      card: false,
      test: false,
      nets: false,
      invoice: false,
    },
    form: {
      address: getFormValueFromCookie("address", ""),
      city: getFormValueFromCookie("city", ""),
      deliveryMethod: getFormValueFromCookie("deliveryMethod", ""),
      email: getFormValueFromCookie("email", ""),
      name: getFormValueFromCookie("name", ""),
      onSiteDeliveryWhere: getFormValueFromCookie("onSiteDeliveryWhere", ""),
      paymentMethod: getFormValueFromCookie('paymentMethod', null),
      paymentTestCode: getFormValueFromCookie('paymentTestCode', ''),
      phone: getFormValueFromCookie('phone', ''),
      source: getFormValueFromCookie('source', null),
      table: getFormValueFromCookie('table', ''),
      takeAwayWhen: getFormValueFromCookie('takeAwayWhen', 'now'),
      deliveryDate: getFormValueFromCookie('deliveryDate', ''),
      deliveryStart: getFormValueFromCookie('deliveryStart', ''),
      deliveryEnd: getFormValueFromCookie('deliveryEnd', ''),
      tipPercent: getFormValueFromCookie('tipPercent', 0),
      venueArea: getFormValueFromCookie('venueArea', ''),
      venueTable: getFormValueFromCookie('venueTable', ''),
      zip: getFormValueFromCookie('zip', ''),
      invoiceBookingCode: getFormValueFromCookie('invoiceBookingCode', ''),
      invoiceMessage: getFormValueFromCookie('invoiceMessage', ''),
      promoCode: getFormValueFromCookie('promoCode', ''),
      customFields: getFormValueFromCookie('customFields', {}),
    },
  };
};



const getFormValueFromCookie = (field, defaultValue) => {
  try {
    let value;
    const formCookie = cookie.load(formCookieName);
    if (formCookie) {
      value = formCookie[field];
    } else {
      value = cookie.load(getLegacyCookieName(field));
    }
    const query = qs.parse(window.location.search);
    if (query[field]) {
      // eslint-disable-next-line default-case
      switch (field) {
        case 'deliveryMethod':
        case 'onSiteDeliveryWhere':
        case 'venueArea':
        case 'venueTable':
          value = query[field];
          break;
      }
    }
    return value || defaultValue;
  } catch (error) {
    return defaultValue;
  }
};

const getLegacyCookieName = (field) => `cookie.${field}`;

export const saveFormToCookie = (form) => {
  const cookieDurationDays = 90;
  cookie.save(formCookieName, JSON.stringify(form), { path: "/", maxAge: 60 * 60 * 24 * cookieDurationDays });
};

export const getDefaultValueForMethod = (methods) => {
  let numTrue = 0;
  let lastTrueKey = "";
  for (let key of Object.keys(methods)) {
    if (methods[key] === true) {
      numTrue++;
      lastTrueKey = key;
    }
  }
  return numTrue === 1 ? lastTrueKey : "";
};
