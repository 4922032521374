import { httpPatch } from "../../utils/http-utils";

const MENU_ITEM_PATCH_FIELDS = [
    'name',
    'description',
    'grossPrice',
    'vatRate',
    'unit',
    'inStock',
    'menuId',
    'sortOrder',
    'restrictTimeType',
    'restrictTimeFrom',
    'restrictTimeTo',
    'menuExtraId',
    'isVisible',
    'discount',
    'restrictDate',
    'restrictTimeStart',
    'restrictTimeEnd',
    'productImage',
];


export async function updateMenuItemApi(menuItemId, data) {
    return await httpPatch(`/api/2/menu-item/${menuItemId}`, data, MENU_ITEM_PATCH_FIELDS);
}
