import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

// reducers

import venueReducer from "./state/venue/reducer";
import cartReducer from "./state/cart/reducer";
import checkoutReducer from "./state/checkout-slice";
import transactionReducer from "./state/transaction/reducer";
import cartItemReducer from "./state/cart-item/reducer";

import backofficeAuthReducer from "./state/bo-auth-slice";
import backofficeMenuReducer from "./state/bo-menu-slice";
import backofficeNavReducer from "./state/bo-nav-slice";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// prepare saga middleware
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  venue: venueReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  transaction: transactionReducer,
  cartItem: cartItemReducer,
  boMenu: backofficeMenuReducer,
  boNav: backofficeNavReducer,
  boAuth: backofficeAuthReducer,
});

export type RootState = ReturnType<typeof rootReducer>;


// create store
export const store = createStore(rootReducer, composeEnhancer(applyMiddleware(...[thunk])));
