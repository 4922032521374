import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import cogoToast from "cogo-toast";
import { omit } from "lodash";
import { updateMenuApi } from "../backoffice/api/menu-api";
import { updateMenuItemApi } from "../backoffice/api/menu-item-api";
import { showSaveToast } from "../utils/toasts";


const initialState = {
  menuExtrasById: null,
  menusById: null,
  menuItemsById: null,
  loading: false,
  ignorePriceSync: null,
};


const boMenuSlice = createSlice({
  name: "bo-menu",
  initialState: initialState,
  reducers: {
    LOAD_MENU_AND_ITEMS_SUCCESS(state, action) {
      state.menusById = action.payload.menusById;
      state.menuItemsById = action.payload.menuItemsById;
      state.menuExtrasById = action.payload.menuExtrasById;
    },
    DELETE_MENU_SUCCESS(state, action) {
      delete state.menusById[action.payload];
    },
    DELETE_MENU_ITEM_SUCCESS(state, action) {
      delete state.menuItemsById[action.payload];
    },
    UPDATE_MENU_ITEM_SUCCESS(state, action) {
      const { menuItemId, updateFields } = action.payload;
      state.menuItemsById[menuItemId] = { ...state.menuItemsById[menuItemId], ...updateFields };
    },
    CREATE_MENU_SUCCESS(state, action) {
      state.menusById[action.payload.id] = action.payload;
    },
    CREATE_MENU_ITEM_SUCCESS(state, action) {
      state.menuItemsById[action.payload.id] = action.payload;
    },
    BATCH_UPDATE_MENU_ITEMS_SUCCESS(state, action) {
      const menuItems = action.payload;
      for (let item of menuItems) {
        state.menuItemsById[item.id] = { ...state.menuItemsById[item.id], ...item };
      }
    },
    UPDATE_MENU_SUCCESS(state, action) {
      const { menuId, updateFields } = action.payload;
      state.menusById[menuId] = { ...state.menusById[menuId], ...updateFields };
    },
    BATCH_UPDATE_MENU_SUCCESS(state, action) {
      const menus = action.payload;
      for (let menu of menus) {
        state.menusById[menu.id] = { ...state.menusById[menu.id], ...menu };
      }
    },
    UPDATE_IGNORE_PRICE_SYNC(state, action) {
      state.ignorePriceSync = action.payload;
    },
    setLoading(state, action) {
      const loading = action.payload;
      state.loading = loading;
    },
  },
});

export const {
  LOAD_MENU_AND_ITEMS_SUCCESS,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_ITEM_SUCCESS,
  UPDATE_IGNORE_PRICE_SYNC,
  CREATE_MENU_SUCCESS,
  UPDATE_MENU_SUCCESS,
  BATCH_UPDATE_MENU_SUCCESS,
  CREATE_MENU_ITEM_SUCCESS,
  UPDATE_MENU_ITEM_SUCCESS,
  BATCH_UPDATE_MENU_ITEMS_SUCCESS,
} = boMenuSlice.actions;

export default boMenuSlice.reducer;

// ---------
// thunks

export const loadMenuAndItems = () => async (dispatch) => {
  dispatch(boMenuSlice.actions.setLoading(true));
  const response = await axios.get("/api/backoffice/menu");
  dispatch(LOAD_MENU_AND_ITEMS_SUCCESS(response.data));
  dispatch(boMenuSlice.actions.setLoading(false));
};

// ------------------------------
// menu actions
// ------------------------------

export const deleteMenu = (menuId) => async (dispatch) => {
  try {
    await axios.delete(`/api/2/menu/${menuId}`);
    dispatch(DELETE_MENU_SUCCESS(menuId));
    cogoToast.success("Meny borttagen");
  } catch (error) {
    cogoToast.error("Ett fel uppstod");
  }

};

export const createMenu = (updateFields) => async (dispatch) => {
  // do this in the end to make it look like the update happens faster
  try {
    const response = await axios.post(`/api/2/menu/`, updateFields);
    dispatch(CREATE_MENU_SUCCESS(response.data));
    cogoToast.success("Meny skapad");
  } catch (error) {
    cogoToast.error("Ett fel uppstod");
  }
};

export const updateMenu = (menuId, updateFields) => async (dispatch) => {
  if (await showSaveToast(updateMenuApi(menuId, updateFields))) {
    dispatch(UPDATE_MENU_SUCCESS({ menuId, updateFields }));
  }
};

// ------------------------------
// menu item actions
// ------------------------------

export const createMenuItem = (updateFields) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/2/menu-item/`, updateFields);
    cogoToast.success("Artikel skapad");
    dispatch(CREATE_MENU_ITEM_SUCCESS(response.data));
  } catch (error) {
    cogoToast.error("Ett fel uppstod");
  }
};

export const deleteMenuItem = (menuItemId) => async (dispatch) => {
  try {
    await axios.delete(`/api/2/menu-item/${menuItemId}`);
    cogoToast.success("Artikel borttagen");
    dispatch(DELETE_MENU_ITEM_SUCCESS(menuItemId));
  } catch (error) {
    cogoToast.error("Ett fel uppstod");
  }

};

export const updateMenuItem = (menuItemId, updateFields) => async (dispatch) => {
  dispatch(UPDATE_MENU_ITEM_SUCCESS({ menuItemId, updateFields }));
  showSaveToast(updateMenuItemApi(menuItemId, updateFields));
};

export const batchUpdateMenuItems = (menuItems) => async (dispatch) => {
  dispatch(BATCH_UPDATE_MENU_ITEMS_SUCCESS(menuItems));
  showSaveToast(menuItems.map(item => updateMenuItemApi(item.id, omit(item, 'id'))))
};

export const batchUpdateMenu = (menus) => async (dispatch) => {
  dispatch(BATCH_UPDATE_MENU_SUCCESS(menus));
  showSaveToast(menus.map(entry => updateMenuApi(entry.id, omit(entry, 'id'))));
};

export const copyMenuItems = (menuItemIds, menuId) => async (dispatch) => {
  if (menuId > 0) {

    const result = await Promise.all(menuItemIds.map(menuItemId => axios.post("/api/2/menu-item/copy", {
      sourceMenuItemId: menuItemId,
      targetMenuId: menuId,
    }))
    );
    for (const { data: item } of result) {
      dispatch(CREATE_MENU_ITEM_SUCCESS(item));
    }
    cogoToast.success("Artiklar kopierade");
  }
};
