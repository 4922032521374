import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import App from "./App";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://604764e1e6434cc993157976d1dbbc3c@sentry.io/1370728",
  });
}

let root = document.getElementById("root");

ReactDOM.render(<App />, root);
