import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import GAListener from "./components/GAListener";
import ReactGA from "react-ga4";
import React, { useState, useEffect, lazy } from "react";
import ScrollToTop from "./components/ScrollToTop";
import history from "./history";
import LinearProgress from "@mui/material/LinearProgress";

// load styles
import "./styles/main.scss";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import { initLocale } from "./utils/localization";
import { setAppUrl } from "./utils/urls";
import ErrorBoundary from "./components/ErrorBoundry";

ReactGA.initialize("G-R26YSNCG0R");

const BackofficeApp = lazy(() => import("./backoffice/BackofficeApp"));
const HomeScreen = lazy(() => import("./screens/HomeScreen"));
const MenuApp = lazy(() => import("./menu/MenuApp"));

const theme = createTheme({
  typography: {
    fontSize: 13,
  },
});

const App = () => {
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    const load = async () => {
      await initLocale("sv-SE");
      setInitDone(true);
    };
    load();
  }, []);

  const convertLangToLocale = (lang) => {
    switch (lang) {
      case "sv":
        return "sv-SE";
      case "en":
        return "en-US";
      default:
        console.warn(`not able to convert url lang {lang} to locale`);
        return null;
    }
  };

  return (
    <Provider store={store}>
      {initDone && (
        <React.StrictMode>
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <React.Suspense fallback={<LinearProgress />}>
                <Router history={history}>
                  <ScrollToTop>
                    <GAListener>
                      <Switch>
                        <Route exact path="/" component={HomeScreen} />
                        <Route
                          path="/menu/:venueId([0-9]+)"
                          component={(props) => {
                            // redirect to a version of the menu with the language in its
                            const localizedLocation = { ...props.location };
                            localizedLocation.pathname = localizedLocation.pathname.replace(/^\/menu\//g, "/menu/sv/");
                            return <Redirect to={localizedLocation} {...props} />;
                          }}
                        />
                        <Route
                          path="/menu/:lang(en|sv)/:venueId([0-9]+)"
                          component={(props) => {
                            const { params, url } = props.match;
                            setAppUrl(url);
                            initLocale(convertLangToLocale(params.lang));
                            return <MenuApp {...props} />;
                          }}
                        />
                        <Route path="/backoffice" component={(props) => <BackofficeApp {...props} />} />
                      </Switch>
                    </GAListener>
                  </ScrollToTop>
                </Router>
              </React.Suspense>
            </ThemeProvider>
          </ErrorBoundary>
        </React.StrictMode>
      )}
    </Provider>
  );
};

export default App;
