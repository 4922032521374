import { httpPatch } from "../../utils/http-utils";


const MENU_UPDATE_FIELDS = [
    'name',
    'sortOrder',
    'visible',
    'menuExtraId',
    'description',
    'isArchived',
];


export async function updateMenuApi(menuId, data) {
    return httpPatch(`/api/2/menu/${menuId}`, data, MENU_UPDATE_FIELDS);
}
