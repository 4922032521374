import { isEqual, omit, filter } from "lodash";
import cookie from "react-cookies";

const cartCookieKey = "cart";

/**
 * make sure that the cart list items are unique. if 2 are the same then merge
 * them together to make it easier for the end user to understand it all.
 * @param {*} cartItems
 */
export const mergeEqualCartItems = (cartItems) => {
  for (let curr = 0; curr < cartItems.length - 1; curr++) {
    for (let next = curr + 1; next < cartItems.length; next++) {
      if (isEqual(omit(cartItems[curr], "numItems"), omit(cartItems[next], "numItems"))) {
        cartItems[curr].numItems += cartItems[next].numItems;
        cartItems[next].numItems = 0;
      }
    }
  }
  return filter(cartItems, (item) => item.numItems > 0);
};

export const persistCartToCookie = (state) => {
  cookie.save(cartCookieKey, state, { path: "/" });
};

export const getCartCookieValue = () => cookie.load(cartCookieKey);
